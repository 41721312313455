import { useState } from 'react';
import PlayerEditInterface from './player-edit-interface';
import { useDispatch } from 'react-redux';
import { createPlayer } from '../redux/actions';

const AddPlayer = () => {
	const [editMode, setEditMode] = useState(false);
	const dispatch = useDispatch();

	const onPlayerCreationSubmit = (name, color) => {
		dispatch(createPlayer({ name: name, color: color }));
		setEditMode(false);
	};

	return (
		<div className="cos-12 col-md-6">
			<div className="card">
				{editMode ? (
					<div className="card-body">
						<PlayerEditInterface
							defaultColor="#FF0000"
							defaultName="Bernd"
							onSubmit={onPlayerCreationSubmit}
						/>
					</div>
				) : (
					<div className="card-body d-grid">
						<button
							type="button"
							className="btn btn-light"
							onClick={() => setEditMode(true)}
						>
							<i className="bi bi-person-plus"></i> Spieler
							hinzufügen
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default AddPlayer;
