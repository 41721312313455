import './App.css';
import { useDispatch } from 'react-redux';
import { appLoaded } from './redux/actions';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Games from './components/games';
import Players from './components/players';

function App() {
	const games = useSelector((state) => state.games);
	const players = useSelector((state) => state.players);
	const [tab, setTab] = useState(1);
	const [searchTerm, setSearchTerm] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(appLoaded(null));
	}, [dispatch]);

	const renderContent = () => {
		switch (tab) {
			default:
			case 1:
				return <Games games={games} players={players} searchTerm={searchTerm} />;
			case 2:
				return (
					<div className="container-fluid">
						<Players players={players} />
					</div>
				);
		}
	};

	return (
		<div className="App">
			{/* <header className="App-header">Exit log</header> */}
				<ul className="nav nav-tabs pt-3">
					<li className="nav-item mx-3">
						<button
							className={`nav-link ${tab === 1 ? 'active' : ''}`}
							aria-current="page"
							onClick={() => setTab(1)}
						>
							Spiele
						</button>
					</li>
					<li className="nav-item">
						<button
							className={`nav-link ${tab === 2 ? 'active' : ''}`}
							onClick={() => setTab(2)}
						>
							Spieler
						</button>
					</li>
					<li className="flex-fill"></li>
					<li className="me-3" style={{maxWidth: '40%'}}>
						<input 
							className="form-control me-2"
							type="search"
							placeholder="Suche"
							aria-label="Suche"
							onChange={(event) => setSearchTerm(event.target.value)}
						/>
					</li>
				</ul>
			<div className="p-3">{renderContent()}</div>
		</div>
	);
}

export default App;
