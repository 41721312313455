import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameImageUrl } from '../redux/actions';
import { difficulty_map } from './constants';

const GameListItem = ({ game, index }) => {
	const imageUrl = useSelector((state) => state.images[game.id]);
	const players = useSelector((state) => state.players);
	const [active, setActive] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		if (!imageUrl) {
			dispatch(getGameImageUrl(game));
		}
	}, [dispatch, game, imageUrl]);

	return (
		<li
			className="list-group-item list-group-item-action"
			style={{ cursor: 'pointer' }}
			onClick={() => setActive(!active)}
		>
			<div className="w-100 d-flex justify-content-between">
				<div>
					{index} - <span className="fw-bold">{game.title}</span>
				</div>
				<div>
					<span className="badge bg-primary rounded-pill">
						{game.player_ids.length}
					</span>
				</div>
			</div>
			{(game.difficulty || game.year_published) && 
				<div>
					{game.difficulty &&
						<span className={`badge bg-${difficulty_map[game.difficulty_rank]} rounded-pill fst-normal`}>
							{game.difficulty}
						</span>
					}
					{game.year_published &&
						<span className='ms-2'>
							veröffentlicht {game.year_published}
						</span>
					}
				</div>
			}
			{active && (
				<div className="container-fluid p-0">
					<div className='row mt-2 mb-2'>
						<div className="col">
							<img
								src={`${imageUrl}`}
								width="auto"
								alt=""
								style={{ maxHeight: '250px', maxWidth: '100%' }}
							/>
						</div>
						<div className="col">
							Gespielt von:
							<ul className="list-group list-group-flush">
								{game.player_ids.map((playerId, index) => (
									<li className="list-group-item" key={index}>
										{
											players.find(
												(element) => element.id === playerId
											).name
										}
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='row'>
						<div className='col12'>
							<hr />
						</div>
					</div>
				</div>
			)}
		</li>
	);
};

export default GameListItem;
