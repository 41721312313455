import { useEffect, useState } from 'react';

const LoadingIndicator = () => {
	const [counter, setCounter] = useState(0);

	const maxTime = 35;

	useEffect(() => {
		counter < maxTime && setTimeout(() => setCounter(counter + 1), 1000);
	}, [counter]);
	return (
		<div className="text-center">
			<div
				className="progress"
				role="progressbar"
				aria-label="Default striped example"
				aria-valuenow={Number((100 / maxTime) * counter)}
				aria-valuemin="0"
				aria-valuemax="100"
			>
				<div
					className="progress-bar progress-bar-striped"
					style={{ width: `${(100 / maxTime) * counter}%` }}
				>
					{counter}  s
				</div>
			</div>
			<div>
				Der Server wird gestartet. Das kann bis zu 30 Sekunden dauern.
			</div>
		</div>
	);
};

export default LoadingIndicator;
