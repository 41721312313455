/* eslint-disable no-console */

import { call, put, takeEvery } from 'redux-saga/effects';
import {
	addGameToPlayer as addGameToPlayerAction,
	appLoaded,
	changePlayerProperties,
	createPlayer as createPlayerAction,
	deletePlayer as deletePlayerAction,
	gameImageUrlReceived,
	getGameImageUrl,
	receiveDifficultyList,
	receiveGameList,
	receivePlayerList,
	removeGameFromPlayer as removeGameFromPlayerAction,
} from './actions';
import { xml2js } from 'xml-js';

const ENDPOINT = `${process.env.REACT_APP_GAME_SERVER_URL}/api`;

const getGames = async () => {
	const response = await fetch(`${ENDPOINT}/games`);
	const games = await response.json();
	return games;
};

const getDifficulties = async () => {
	const response = await fetch(`${ENDPOINT}/difficulty`);
	const difficulties = await response.json();
	return difficulties;
};

const getPlayers = async () => {
	const response = await fetch(`${ENDPOINT}/players`);
	const players = await response.json();
	return players;
};

const callCreatePlayerAPI = async (name, color) => {
	const response = await fetch(`${ENDPOINT}/players`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify({ name, color }),
	});
	const players = await response.json();

	return players;
};

const updatePlayer = async (player) => {
	const response = await fetch(`${ENDPOINT}/players/${player.id}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'POST',
		body: JSON.stringify(player),
	});
	const players = await response.json();

	return players;
};

const callApiAddGameToPlayer = async (playerId, gameId) => {
	const response = await fetch(
		`${ENDPOINT}/players/${playerId}/games/${gameId}`,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'POST',
		}
	);
	const players = await response.json();

	return players;
};

const callApiRemoveGameFromPlayer = async (playerId, gameId) => {
	const response = await fetch(
		`${ENDPOINT}/players/${playerId}/games/${gameId}`,
		{
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'DELETE',
		}
	);
	const players = await response.json();

	return players;
};

const callDeletePlayerAPI = async (playerId) => {
	const response = await fetch(`${ENDPOINT}/players/${playerId}`, {
		headers: {
			'Content-Type': 'application/json',
		},
		method: 'DELETE',
	});
	const players = await response.json();

	return players;
};

const getGameDataFromExternal = async (game) => {
	const response = await fetch(
		`https://api.geekdo.com/xmlapi2/thing?id=${game.bgg_id}`,
		{
			method: 'GET',
			mode: 'cors',
		}
	);
	const data = await response.text();

	const {
		items: {
			item: {
				image: { _text: imageUrl },
			},
		},
	} = xml2js(data, {
		compact: true,
		ignoreDeclaration: true,
		alwaysChildren: true,
	});
	return imageUrl;
	// const data = await response.json();
};

function* setupData() {
	const games = yield call(getGames);
	const difficulties = yield call(getDifficulties);
	const players = yield call(getPlayers);
	yield put(receiveGameList(games));
	yield put(receivePlayerList(players));
	yield put(receiveDifficultyList(difficulties));
}

function* changePlayerData(action) {
	const playerData = action.payload;

	console.log(playerData);
	const players = yield call(updatePlayer, playerData);
	if (players) {
		yield put(receivePlayerList(players));
	}
}

function* receiveGameData(action) {
	const game = action.payload;
	const imageUrl = yield call(getGameDataFromExternal, game);

	yield put(gameImageUrlReceived({ gameId: game.id, imageUrl }));
}

function* addGameToPlayer(action) {
	const { playerId, gameId } = action.payload;

	const players = yield call(callApiAddGameToPlayer, playerId, gameId);
	if (players) {
		yield put(receivePlayerList(players));
	}
	const games = yield call(getGames);

	if (games) {
		yield put(receiveGameList(games));
	}
}

function* removeGameFromPlayer(action) {
	const { playerId, gameId } = action.payload;

	const players = yield call(callApiRemoveGameFromPlayer, playerId, gameId);
	if (players) {
		yield put(receivePlayerList(players));
	}
	const games = yield call(getGames);

	if (games) {
		yield put(receiveGameList(games));
	}
}

function* createPlayer(action) {
	const { name, color } = action.payload;
	const players = yield call(callCreatePlayerAPI, name, color);
	if (players) {
		yield put(receivePlayerList(players));
	}
}

function* deletePlayer(action) {
	const playerId = action.payload;
	const players = yield call(callDeletePlayerAPI, playerId);
	if (players) {
		yield put(receivePlayerList(players));
	}
	const games = yield call(getGames);

	if (games) {
		yield put(receiveGameList(games));
	}
}

function* appSaga() {
	yield takeEvery(`${appLoaded}`, setupData);
	yield takeEvery(`${changePlayerProperties}`, changePlayerData);
	yield takeEvery(`${getGameImageUrl}`, receiveGameData);
	yield takeEvery(`${addGameToPlayerAction}`, addGameToPlayer);
	yield takeEvery(`${removeGameFromPlayerAction}`, removeGameFromPlayer);
	yield takeEvery(`${createPlayerAction}`, createPlayer);
	yield takeEvery(`${deletePlayerAction}`, deletePlayer);
}

export default appSaga;
