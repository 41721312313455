import { useState } from 'react';
import { useSelector } from 'react-redux';

const PlayerEditInterface = ({ defaultName, defaultColor, onSubmit }) => {
	const [newName, setNewName] = useState(defaultName);
	const [newColor, setNewColor] = useState(defaultColor);

	const activeRequestState = useSelector((state) => state.requestState);

	return (
		<>
			<div className="card-title d-flex justify-content-between">
				<input
					type="text"
					className="form-control me-2"
					id="exampleFormControlInput1"
					placeholder="Dein Name"
					onChange={(e) => setNewName(e.target.value)}
					defaultValue={defaultName}
				/>
				<input
					type="color"
					className="form-control form-control-color"
					id="exampleColorInput"
					defaultValue={defaultColor}
					onChange={(e) => setNewColor(e.target.value)}
					title="Farbe ändern"
				/>
			</div>
			<div className="d-grid">
				<button
					type="button"
					className="btn btn-primary btn-sm"
					onClick={() => onSubmit(newName, newColor)}
					disabled={activeRequestState !== null}
				>
					{activeRequestState !== null ? (
						<span
							class="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
					) : (
						'speichern'
					)}
				</button>
			</div>
		</>
	);
};

export default PlayerEditInterface;
