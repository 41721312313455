/* eslint-disable no-undef */

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "@redux-saga/core";
import appReducer from './reducers';
import appSaga from './saga';

const configureAppStore = () => {
  
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({ reducer: appReducer, middleware: [sagaMiddleware] });

  sagaMiddleware.run(appSaga);

  return store;
};

export default configureAppStore;