import { createAction } from '@reduxjs/toolkit';

export const appLoaded = createAction('app/LOADED');
export const appClosed = createAction('app/CLOSED');

export const getGameImageUrl = createAction('send/GET_GAME_IMAGE_URL');
export const gameImageUrlReceived = createAction('received/GET_GAME_IMAGE_URL');

export const receiveGameList = createAction('receive/GAME_LIST');
export const receivePlayerList = createAction('receive/PLAYER_LIST');
export const receiveDifficultyList = createAction('receive/DIFFICULTY_LIST');

export const changePlayerProperties = createAction('update/PLAYER_PROPERTIES');
export const addGameToPlayer = createAction('add/PLAYER_GAME');
export const removeGameFromPlayer = createAction('remove/PLAYER_GAME');

export const createPlayer = createAction('create/PLAYER');
export const deletePlayer = createAction('delete/PLAYER');
