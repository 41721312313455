import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addGameToPlayer } from '../redux/actions';

const AddGameToPlayer = ({ player }) => {
	const [searchTerm, setSearchTerm] = useState(null);
	const [addMode, setAddMode] = useState(null);
	const games = useSelector((state) => state.games);
	const dispatch = useDispatch();

	return (
		<li
			className="list-group-item d-flex flex-column justify-content-center list-group-item-action"
			style={{ cursor: 'pointer' }}
		>
			{addMode === player.id ? (
				<div className="d-flex flex-row w-100">
					<div className="d-flex flex-column w-100">
						<div className="w-100">
							<input
								type="text"
								autoFocus
								onChange={(e) => setSearchTerm(e.target.value)}
								className="form-control form-control-sm w-100"
								id="newGame"
								placeholder="Spielesuche"
							/>
						</div>
						<ul
							className="w-100 p-0 overflow-auto"
							style={{ maxHeight: '250px' }}
						>
							{games
								.filter((game) => {
									if (searchTerm === null) {
										return true;
									}
									return (
										game.title
											.toLowerCase()
											.indexOf(
												searchTerm?.toLowerCase()
											) >= 0 &&
										!player.game_ids.includes(game.id)
									);
								})
								.map((game, index) => (
									<li
										key={index}
										value={game.id}
										className="d-flex flex-row list-group-item justify-content-between align-items-start list-group-item-action"
									>
										<span>{game.title}</span>
										<button
											type="button"
											className="btn btn-primary"
											onClick={() =>
												dispatch(
													addGameToPlayer({
														playerId: player.id,
														gameId: game.id,
													})
												)
											}
										>
											<i className="bi bi-plus-circle"></i>
										</button>
									</li>
								))}
						</ul>
					</div>
					<div className="d-flex flex-column justify-content-start ms-3">
						<button
							type="button"
							className="btn-close mt-1"
							aria-label="Close"
							onClick={() => setAddMode(null)}
						></button>
					</div>
				</div>
			) : (
				<div
					className="w-100 text-center"
					onClick={() => {
						setAddMode(player.id);
					}}
				>
					<i className="bi bi-plus-circle"></i>
				</div>
			)}
		</li>
	);
};

export default AddGameToPlayer;
