import { Fragment, useState } from 'react';
import GameListItem from './game-list-item';
import LoadingIndicator from './loading-indicator';
import { useSelector } from 'react-redux';

const Games = ({ games, players, searchTerm }) => {
	const difficulties = useSelector(state => state.difficulties)
	const [filteredPlayerIds, setFilteredPlayerIds] = useState([]);
	const [filteredDifficulties, setFilteredDifficulties] = useState([]);

	const filterGames = (game) => {
		if (filteredPlayerIds.length === 0 && filteredDifficulties.length === 0) {
			if (searchTerm) {
				return game.title.toLowerCase().includes(searchTerm.toLowerCase())
			}
			return true;
		}

		if (filteredPlayerIds.length === 0) {
			return filteredDifficulties.includes(game.difficulty_rank) && game.title.toLowerCase().includes(searchTerm.toLowerCase())
		}

		if (filteredDifficulties.length === 0) {
			return game.player_ids.every((playerId) => !filteredPlayerIds.includes(playerId)) && game.title.toLowerCase().includes(searchTerm.toLowerCase())
		}

		return game.player_ids.every(
			(playerId) => !filteredPlayerIds.includes(playerId)
		) && filteredDifficulties.includes(game.difficulty_rank) && game.title.toLowerCase().includes(searchTerm.toLowerCase());
	};

	return (
		<div className="container-fluid p-0">
			<div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
				<div className="mb-2 btn-group input-group w-auto align-self-start" role="group">
					<div className="input-group-text" id="btnGroupAddon">
						Noch nicht gespielt von:
					</div>
						{players.map((player, index) => (
							<Fragment key={index}>
								<input
									type="checkbox"
									className="btn-check"
									id={`btncheck${player.id}`}
									autoComplete="off"
									onClick={() => {
										if (filteredPlayerIds.includes(player.id)) {
											setFilteredPlayerIds(
												filteredPlayerIds.filter(
													(item) => item !== player.id
												)
											);
										} else {
											setFilteredPlayerIds([...filteredPlayerIds, player.id]);
										}
									}}
								/>
								<label
									className="btn btn-outline-primary flex-grow-0"
									htmlFor={`btncheck${player.id}`}
								>
									{player.name}
								</label>
							</Fragment>
						))}
				</div>
				<div className="mb-2 btn-group input-group w-auto align-self-start" role="group">
					<div className="input-group-text" id="btnGroupAddon">
						Schwierigkeit:
					</div>
						{difficulties.map(({rank, name}) => (
							<Fragment key={rank}>
								<input
									type="checkbox"
									className="btn-check"
									id={`btn-difficulty-check${rank}`}
									autoComplete="off"
									onClick={() => {
										if (filteredDifficulties.includes(rank)) {
											setFilteredDifficulties(
												filteredDifficulties.filter(
													(item) => item !== rank
												)
											);
										} else {
											setFilteredDifficulties([...filteredDifficulties, rank]);
										}
									}}
								/>
								<label
									className="btn btn-outline-primary flex-grow-0"
									htmlFor={`btn-difficulty-check${rank}`}
								>
									{name}
								</label>
							</Fragment>
						))}
				</div>
				<div className='mb-2'>{games.filter(filterGames).length} Ergebisse</div>
			</div>
			{games.length === 0 && <LoadingIndicator />}
			<ul className="list-group">
				{games.filter(filterGames).map((game, index) => {
					return (
						<GameListItem key={index} game={game} index={index} />
					);
				})}
			</ul>
		</div>
	);
};

export default Games;
