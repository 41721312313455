import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	changePlayerProperties,
	deletePlayer,
	removeGameFromPlayer,
} from '../redux/actions';
import AddGameToPlayer from './add-game-to-player';
import AddPlayer from './add-player';
import PlayerEditInterface from './player-edit-interface';
import LoadingIndicator from './loading-indicator';

const Players = ({ players }) => {
	const [editMode, setEditmode] = useState(null);
	const games = useSelector((state) => state.games);
	const dispatch = useDispatch();

	const onPlayerEditSubmit = (name, color) => {
		dispatch(
			changePlayerProperties({
				id: editMode,
				name: name,
				color: color,
			})
		);
		setEditmode(null);
	};

	const onPlayerDelete = (playerId) => {
		dispatch(deletePlayer(playerId));
	};

	return (
		<div className="row gx-3 gy-3">
			{players.length === 0 && <LoadingIndicator />}
			{players.map((player, index) => (
				<div className="col-12 col-md-6" key={index}>
					<div className="card">
						<div className="card-body">
							{editMode === player.id ? (
								<>
									<PlayerEditInterface
										defaultColor={player.color}
										defaultName={player.name}
										onSubmit={onPlayerEditSubmit}
									/>
									<div className="d-grid mt-2">
										<button
											type="button"
											className="btn btn-outline-danger btn-sm"
											onClick={() =>
												onPlayerDelete(player.id)
											}
										>
											Spieler löschen
										</button>
									</div>
								</>
							) : (
								<>
									<h5 className="card-title d-flex justify-content-between align-items-center">
										<span
											className="fw-bold"
											style={{ color: player.color }}
										>
											{player.name}
										</span>
										<button
											type="button"
											className="btn btn-light btn-sm"
											onClick={() => {
												setEditmode(player.id);
											}}
										>
											<i className="bi bi-pencil-square"></i>
										</button>
									</h5>

									<h6 className="card-subtitle mb-2 text-body-secondary">
										{player.game_ids.length} gespielte
										Spiele
									</h6>
								</>
							)}
						</div>
						<ul className="list-group list-group-flush">
							{player.game_ids.map((gameId) => {
								return (
									<li
										className="list-group-item d-flex flex-row justify-content-between align-items-start"
										key={gameId}
									>
										{
											games.find(
												(game) => game.id === gameId
											).title
										}{' '}
										<button
											type="button"
											className="btn btn-danger btn-sm"
											onClick={() => {
												dispatch(
													removeGameFromPlayer({
														playerId: player.id,
														gameId: gameId,
													})
												);
											}}
										>
											<i className="bi bi-trash"></i>
										</button>
									</li>
								);
							})}

							<AddGameToPlayer player={player} />
						</ul>
					</div>
				</div>
			))}

			<AddPlayer />
		</div>
	);
};

export default Players;
