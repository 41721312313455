import {
	receiveGameList,
	receivePlayerList,
	gameImageUrlReceived,
	changePlayerProperties,
	receiveDifficultyList,
} from './actions';

export const requestStates = {
	CHANGING_PLAYER_PROPERTIES: 'CHANGING_PLAYER_PROPERTIES',
};

const initialState = {
	games: [],
	players: [],
	difficulties: [],
	images: {},
	requestState: null,
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case `${receiveGameList}`:
			return {
				...state,
				games: action.payload,
			};
		case `${changePlayerProperties}`:
			return {
				...state,
				requestState: requestStates.CHANGING_PLAYER_PROPERTIES,
			};
		case `${receivePlayerList}`:
			return {
				...state,
				requestState: null,
				players: action.payload,
			};
		case `${receiveDifficultyList}`:
			return {
				...state,
				difficulties: action.payload,
			};
		case `${gameImageUrlReceived}`:
			return {
				...state,
				images: {
					...state.images,
					[action.payload.gameId]: action.payload.imageUrl,
				},
			};
		default:
			return state;
	}
};

export default appReducer;
